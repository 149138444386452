import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIconRounded from '@material-ui/icons/InfoRounded';
import { colors } from '../../helper/Variables';
import { Text } from '../../helper/Typography';
import clsx from 'clsx';
import ShowMoreText from 'react-show-more-text';
import { CheckboxItem } from './CheckBoxItem';
import { CookieDescriptions } from './CookieDescriptions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '30px',
  },
  expansionPanel: {
    backgroundColor: colors.black,
    color: colors.white,
  },
  bottomBorder: {
    borderBottom: `solid  ${colors.greyDarkest} 1px`,
  },
  expandIcon: {
    color: colors.white,
  },
  paragraph: {
    margin: '0',
  },
});

interface AccordionProps {
  readMoreLink: string;
  readLessLink: string;
  cookies: Array<any>;
  setCookiesState: Function;
  cookiesState: object;
  locale: string;
}

export const Accordion = ({
  readMoreLink,
  readLessLink,
  cookies,
  setCookiesState,
  cookiesState,
  locale,
}: AccordionProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<object>();

  const handleChange = (event: object, isExpanded: boolean, panel: string) => {
    setExpanded({
      ...expanded,
      [panel]: isExpanded,
    });
  };

  return (
    <div className={classes.root}>
      {cookies.map((cookie: any) => {
        return (
          <ExpansionPanel
            className={clsx(classes.expansionPanel, classes.bottomBorder)}
            expanded={expanded ? expanded[cookie.name] : false}
            onChange={(e: object, expanded: boolean) =>
              handleChange(e, expanded, cookie.name)
            }
          >
            <ExpansionPanelSummary
              expandIcon={
                <>
                  {expanded && expanded[cookie.name] ? (
                    <ExpandMoreIcon className={classes.expandIcon} />
                  ) : (
                    <InfoIconRounded className={classes.expandIcon} />
                  )}
                </>
              }
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <CheckboxItem
                name={cookie.name}
                label={cookie.label}
                checked={
                  cookie.required || cookiesState[cookie.name] === 'true'
                }
                setCookiesState={setCookiesState}
                cookiesState={cookiesState}
                pClass={classes.paragraph}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanel}>
              <Text.p darkTheme={true} className={classes.paragraph}>
                <ShowMoreText lines={7} more={readMoreLink} less={readLessLink}>
                  {CookieDescriptions[cookie.name](locale)}
                </ShowMoreText>
              </Text.p>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </div>
  );
};
export default Accordion;
