import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '../../helper/Typography';
import { colors } from '../../helper/Variables';
import ShowMoreText from 'react-show-more-text';
import { Button } from '../../helper/StyledHelper';
import Accordion from './Accordion';

interface CookieDisclaimerProps {
  cookieData: any;
  locale: string;
  cookies: any;
  setCookie: Function;
  cookieNames: any;
}

export interface CookieData {
  required: boolean;
  name: string;
  label: string;
}

export const CookieDisclaimer = ({
  cookieData,
  locale,
  cookies,
  setCookie,
  cookieNames,
}: CookieDisclaimerProps) => {
  // Copy cookies into component state
  const [cookiesState, setcookiesState] = useState<object>(cookies);

  // Set expire date (150 days) for the hide-consent cookie
  let expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 150);
  const setCookies = () => {
    {
      setCookie('hide-consent', true, {
        expires: expireDate,
      });
      cookieNames().forEach((name: string) => {
        setCookie(name, cookiesState[name]);
      });
    }
  };

  return !cookies['hide-consent'] && cookieData ? (
    <>
      <ContentWrapper>
        <Content>
          <Text.pBold darkTheme={true}>{cookieData.introHeadline}</Text.pBold>
          <Text.p darkTheme>
            <ShowMoreText
              more={cookieData.readMoreLink}
              less={cookieData.readLessLink}
            >
              {cookieData.introText}
            </ShowMoreText>
          </Text.p>
          <Accordion
            locale={locale}
            readMoreLink={cookieData.readMoreLink}
            readLessLink={cookieData.readLessLink}
            cookiesState={cookiesState}
            setCookiesState={setcookiesState}
            cookies={cookieData.cookies}
          ></Accordion>
        </Content>
      </ContentWrapper>
      <ButtonBar>
        <Button onClick={setCookies}>{cookieData.acceptBtn}</Button>
      </ButtonBar>
    </>
  ) : (
    <></>
  );
};

const ContentWrapper = styled.div`
  bottom: 100px;
  width: 100%;
  background-color: black;
  padding: 30px 0 30px 0;
  z-index: 9999;
  position: fixed;
  height: fit-content;
`;

const Content = styled.div`
  max-width: 1585px;
  margin: 0 auto;
  width: 85%;
  overflow: auto;
  max-height: calc(100vh - 300px);

  /* Scroll bar style */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #000;
  }
  ::-webkit-scrollbar-thumb {
    background: #1a1a1a;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #403f3f;
  }
`;

export const ButtonBar = styled.div`
  background-color: black;
  z-index: 9999;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;

  button {
    position: relative;
    font-family: 'Metric-Regular', sans-serif;
    line-height: 1;
    padding: 15px 24px 17px 24px;
    font-size: 16px;
    border-radius: 100px;
    text-align: center;
    box-shadow: 0 0 1px 0 ${colors.white} inset, 0 0 1px 0px ${colors.white};
    border: 1px solid ${colors.white};
    color: ${colors.white};
    min-width: 165px;
    width: fit-content;
    height: 56px;
    box-sizing: border-box;
    background: transparent;
    overflow: hidden;

    transition: 0.3s all ease;

    &:not([disabled]) {
      cursor: pointer;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.2;
      pointer-events: none;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }
`;
