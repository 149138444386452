import React from 'react';
import { Text } from '../../helper/Typography';

export const CookieDescriptions = {
  requiredCookies: (locale: string) =>
    locale === 'de' ? (
      <>
        Diese Cookies sind aus technischen Gründen erforderlich, damit Sie
        unsere Website besuchen und die von uns angebotenen Funktionen nutzen
        können. Darüber hinaus tragen diese Cookies zu einer sicheren und
        ordnungsgemäßen Nutzung der Website bei.
      </>
    ) : (
      <>
        These cookies are necessary for technical reasons so that you can visit
        our website and use the functions we offer. In addition, these cookies
        contribute to the safe and proper use of the website.
      </>
    ),
  gaSnowPlow: (locale: string) =>
    locale === 'de' ? (
      <>
        <Text.pBold darkTheme={true}>Google Analytics</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          Diese Website benutzt Google Analytics incl. der Google
          Analytics-Werbefunktionen. Dabei handelt es sich um einen
          Webanalysedienst der Google Inc. („Google“). Google Analytics
          verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse der Benutzung der Website
          durch Sie ermöglichen. <br /> <br /> Google Analytics wird
          ausschließlich mit aktivierter IP-Anonymisierung einsetzt (sog.
          IP-Masking). Dies bedeutet, dass die IP-Adresse der Nutzer von Google
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          gekürzt wird. Nur in Ausnahmefällen, wenn es z.B. zu technischen
          Ausfällen in Europa kommt, wird die volle IP-Adresse an einen Server
          von Google in den USA übertragen und dort gekürzt. <br /> <br />
          Bei der von Google verwendeten IP-Anonymisierungsmethode wird die
          vollständige IPAdresse zu keinem Zeitpunkt auf eine Festplatte
          geschrieben, da die gesamte Anonymisierung fast unmittelbar nach
          Erhalt der Anforderung im Arbeitsspeicher erfolgt. <br />
          <br />
          Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt. <br />
          <br />
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Website auf der Grundlage
          von Art. 6 Abs. 1 f DSGVO auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen,
          insbesondere auch Funktionen für Display-Werbung sowie Google
          Analytics-Berichte zur Leistung nach demografischen Merkmalen und
          Interessen gegenüber dem Websitebetreiber zu erbringen. <br /> <br />{' '}
          Auch wird Google diese Informationen gegebenenfalls an Dritte
          übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte
          diese Daten im Auftrag von Google verarbeiten. Hierbei wird es sich in
          keinem Fall um personenbezogene Daten handeln. <br /> <br /> In den
          Google Analytics-Berichten zur Leistung nach demografischen Merkmalen
          und Interessen werden über interessenbezogene Werbung von Google
          erlangte Daten und Besucherdaten von Drittanbietern (wie z.B.
          Altersgruppen, oder Interessensgruppen) verwendet. Sie können die
          Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Website vollumfänglich werden nutzen können. Sie können darüber hinaus
          die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem sie das unter
          dem folgenden Link verfügbare Browser-Plugin herunterladen und
          installieren:{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            Google Browser-Plugin herunterladen und installieren.
          </a>
          <br /> <br /> Nähere Informationen zu Nutzungsbedingungen und
          Datenschutz finden Sie{' '}
          <a
            href="https://www.google.de/analytics/terms/de.html"
            target="_blank"
          >
            hier
          </a>{' '}
          bzw.{' '}
          <a
            href="
                 https://www.google.de/intl/de/policies/"
            target="_blank"
          >
            hier.
          </a>{' '}
          Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um
          den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte
          Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. <br />{' '}
          <br />
        </Text.p>
        <Text.pBold darkTheme={true}>Snow Plow</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          Wir setzen auf neugelb.com die Webtracking-Lösung der SnowPlow
          Analytics Limited, d 32-38 Scrutton Street, London, Groß Britannien
          (“SnowPlow”), ein. Wir verwenden die mit Hilfe von Snowplow erhobenen
          Daten ausschließlich für statistische Zwecke. In diesem Fall setzen
          wir zudem nach Ihrer Freigabe einen Cookie in Ihrem Browser, um einen
          wiederkehrenden Nutzer als solchen erkennen zu können. Der
          SnowPlow-Cookie enthält ein Datum und eine nicht zurückrechenbare
          Zufallskombinationsnummer („Hash“) sowie die Nummer der aktuellen
          Session. <br /> <br />
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz und den
          verwendeten Parametern finden Sie{' '}
          <a
            href="https://github.com/snowplow/snowplow/wiki/snowplow-tracker-protocol"
            target="_blank"
          >
            hier .
          </a>
          <br /> <br />
          Durch den Einsatz dieser Verfahren erhalten wir keine
          personenbezogenen Daten über Sie, sondern vielmehr allein statistische
          Informationen über die Nutzung auf neugelb.com. Wir erfahren auf
          diesem Weg zum Beispiel, welche Inhalte von neugelb.com besonders
          beliebt sind, zu welchen Zeiten unsere Website besonders intensiv
          genutzt wird, über welche Seiten unsere Nutzer auf unsere Seite
          gelangen, von wo der Nutzer sich in das Internet eingewählt hat, bevor
          er die Seite besucht, und welche Browser und Betriebssysteme unsere
          Nutzer im Allgemeinen nutzen, wenn sie auf neugelb.com surfen. Diese
          Informationen nutzen wir, um den Content und unsere Seite  laufend
          technisch, gestalterisch und redaktionell zu verbessern und für Sie
          komfortabler zu machen. Anhand der statistischen Erkenntnisse zu
          Browsertyp und Betriebssystem können wir so unser Webdesign
          optimieren. <br />
          <br />
          Snowplow ist eine quelloffene Webtracking-Lösung, die von der Snowplow
          Analytics Ltd., EC2A 4RQ London, UK, verwaltet wird. Die von uns
          eingesetzte Variante von Snowplow verwendet einen Tracking-Pixel und
          einen Cookie, mit dem nachverfolgt werden kann, wann und wie viele
          Nutzer die neugelb.com Website wie oft besucht haben, welche unserer
          Seiten sie in welcher Reihenfolge aufgerufen haben, welcher Browser
          bzw. welches Betriebssystem sie verwenden und auf welche externen
          Links mit Weiterleitungen sie geklickt haben. <br />
          <br />
          Der von uns zur Erfassung dieser Daten verwendete Tracking-Pixel wird
          von unserem Tracking-Server geladen, wenn Sie diesen nach Akzeptanz im
          Cookie Consent freigegeben haben. Die erfassten Daten werden in einer
          sogenannten Tracking-Session gemeinsam mit einer anonymen
          Nutzer-Kennung (eindeutiger Hash aus Browsereigenschaften und dem
          aktuellen Zeitpunkt) gespeichert. Die Datenerhebung und Verarbeitung
          erfolgt dabei zu jedem Zeitpunkt ohne Personenbezug. Insbesondere
          speichern wir keine vollständigen IP-Adressen. <br />
          <br />
          Wenn Sie mit der zuvor beschriebenen Speicherung und Verwendung der
          Nutzungsdaten durch Snowplow nicht einverstanden sind, können Sie
          Ihren Widerspruch gegen die bei dieser Webanalyse-Maßnahmen
          stattfindende Datenerhebung und -verwendung erklären indem Sie die
          Checkbox im Cookie Consent nicht auswählen. Weitere Informationen
          finden Sie in der{' '}
          <a
            href="https://snowplowanalytics.com/privacy-policy/"
            target="_blank"
          >
            Datenschutzerklärung von Snowplow
          </a>
          . Den gesetzten Cookie können Sie über Ihren Browser jederzeit
          löschen. Eine Anleitung finden Sie in der Hilfefunktion Ihres
          Browsers.
        </Text.p>
      </>
    ) : (
      <>
        <Text.pBold darkTheme={true}>Google Analytics</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          This website uses Google Analytics including the Google Analytics
          advertising features. This is a web analysis service of Google Inc.
          ("Google"). Google Analytics uses so-called "cookies", text files that
          are stored on your computer and that allow an analysis of the use of
          the website by you. <br /> <br />
          Google Analytics is used exclusively with activated IP anonymization
          (so-called IP masking). This means that Google's IP address will be
          truncated within member states of the European Union or other parties
          to the Agreement on the European Economic Area. Only in exceptional
          cases, when e.g. technical breakdowns in Europe, the full IP address
          is transmitted toa Google server in the US and shortened there. <br />
          <br /> The IP anonymization method used by Google does not write the
          full IP address to disk at all times because all anonymization occurs
          almost immediately after receiving the request in memory. <br />
          <br /> The IP address submitted by the user's browser will not be
          merged with other data provided by Google. <br />
          <br />
          On behalf of the operator of this website, Google will use this
          information to evaluate your use of the website on the basis of Art. 6
          (1 f) GDPR, to compile reports on website activity and other services
          related to website activity and internet usage, in particular also
          provide display advertising and Google Analytics demographic and
          interest reporting services to the site operator. <br />
          <br />
          Google may also transfer this information to third parties if required
          by law or as far as third parties process this data on behalf of
          Google. This will in no case be personal data. <br /> <br /> The
          Google Analytics demographics and interest reports use third-party
          data and third-party visitor data (such as age groups or interest
          groups) obtained through Google's interest-based advertising.You may
          opt out of the use of cookies by selecting the appropriate settings on
          your browser, however please note that if you do this you may not be
          able to use the full functionality of this website.You can also
          prevent data generated by the cookie as well as data relating to your
          website usage (including your IP address) from being saved or
          processed by Google by downloading and installing the browser plug-in
          available at the following link: Download and install{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            google browser-plugin.
          </a>{' '}
          <br /> <br />
          For more information on the terms of use and data protection go to{' '}
          <a
            href="https://www.google.de/analytics/terms/de.html"
            target="_blank"
          >
            Google Analytics
          </a>{' '}
          or{' '}
          <a href="https://www.google.de/intl/de/policies/" target="_blank">
            Google policies
          </a>
          . Please be advised that on this website, Google Analytics is
          supplemented with the code "anonymizelp" in order to guarantee that IP
          addresses are tracked in anonymised form (so-called IP masking).
        </Text.p>
        <Text.pBold darkTheme={true}>Snow Plow</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          On neugelb.com we use the web tracking solution of SnowPlow Analytics
          Limited, d 32-38 Scrutton Street, London, Great Britain (“SnowPlow”).
          We use the data collected with the help of Snowplow exclusively for
          statistical purposes. In this case, we also set a cookie in your
          browser after you have released it so that we can recognize a
          returning user as such. The SnowPlow cookie contains a date and a
          non-recalculable random combination number (“hash”) as well as the
          number of the current session. <br />
          <br />
          You can find more information on terms of use and data protection as
          well as the parameters used{' '}
          <a
            href="https://github.com/snowplow/snowplow/wiki/snowplow-tracker-protocol"
            target="_blank"
          >
            here.
          </a>{' '}
          <br /> <br />
          By using these procedures, we do not receive any personal data about
          you, but rather only statistical information about the use on
          neugelb.com. In this way, we learn, for example, which content from
          neugelb.com is particularly popular, at what times our website is used
          particularly intensively, via which pages our users access our page,
          from where the user dialed into the Internet before he visits the site
          and which browsers and operating systems our users generally use when
          surfing on neugelb.com. We use this information to continuously
          improve the content and our site technically, creatively and
          editorially and to make it more convenient for you. Based on the
          statistical knowledge of browser type and operating system, we can
          optimize our web design. <br /> <br /> Snowplow is an open source web
          tracking solution managed by Snowplow Analytics Ltd., EC2A 4RQ London,
          UK. The version of Snowplow we use uses a tracking pixel and a cookie
          that can be used to track when, how many and how often users visited
          the neugelb.com website, which of our pages they called up in which
          order, which browser or which operating system you are using and which
          external links with redirects you have clicked on. <br /> <br /> The
          tracking pixel we use to collect this data is loaded by our tracking
          server if you have approved it in the cookie consent after acceptance.
          The recorded data is stored in a so-called tracking session together
          with an anonymous user ID (unique hash from browser properties and the
          current time). The data collection and processing takes place at any
          time without personal reference. In particular, we do not save
          complete IP addresses. <br />
          <br />
          If you do not consent to Snowplow's storage and use of the usage data
          described above, you can object to the data collection and use taking
          place during this web analysis measure by not selecting the checkbox
          in the cookie consent. For more information, see{' '}
          <a
            href="https://snowplowanalytics.com/privacy-policy/"
            target="_blank"
          >
            Snowplow's privacy policy
          </a>{' '}
          . You can delete the cookie at any time via your browser. Instructions
          can be found in the help function of your browser.
        </Text.p>
      </>
    ),
};
