import React from 'react';
import {
  FormControlLabel,
  MuiThemeProvider,
  Checkbox,
  createMuiTheme,
} from '@material-ui/core';
import { Text } from '../../helper/Typography';
import { colors } from '../../helper/Variables';

// We need to use a Theme to overwrite the checkbox styling
const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        color: colors.white,
        '&$checked': {
          color: colors.grey,
        },
      },
    },
  },
});

interface CheckboxItemProps {
  name: string;
  label: string;
  checked: boolean;
  setCookiesState: Function;
  cookiesState: object;
  pClass: any;
}

export const CheckboxItem = ({
  name,
  label,
  checked,
  setCookiesState,
  cookiesState,
  pClass,
}: CheckboxItemProps) => {
  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setCookiesState({
      ...cookiesState,
      [name]: event.target.checked.toString(),
    });
  };

  return (
    <FormControlLabel
      aria-label="Acknowledge"
      onClick={event => event.stopPropagation()}
      onFocus={event => event.stopPropagation()}
      control={
        <MuiThemeProvider theme={theme}>
          <Checkbox
            checked={checked}
            onChange={event => handleCheckbox(event, name)}
            value={name}
          />
        </MuiThemeProvider>
      }
      label={
        <Text.p darkTheme={true} className={pClass}>
          {label}
        </Text.p>
      }
    />
  );
};
