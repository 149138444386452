import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { SimpleImage } from '../../helper/StyledHelper';
import { Text } from '../../helper/Typography';
import { colors } from '../../helper/Variables';
import SocialIcons from '../SocialIcons/SocialIcons';
import { getUrlFromId } from '../../helper/helperServices';
import { routes } from '../../setup/routes';

const excludedLinks = ['home', 'caseStudies', 'articles'];

const Footer = ({ general, darkTheme, lang, showConsent }) => (
  <FooterBar theme={darkTheme ? 'dark' : 'light'}>
    <FooterBlockLeft>
      <FooterLogoContainer className={'showAbove-mobile3'}>
        <SimpleImage src={`/assets/icons/header/logo-white.svg`} />
      </FooterLogoContainer>

      <CopyrightContainer>
        <Text.small>© Neugelb Studios</Text.small>
      </CopyrightContainer>
    </FooterBlockLeft>

    <FooterBlockRight>
      <SocialIcons dark={darkTheme} />

      <LinkGroup theme={'dark'}>
        {routes[0].routes.map(
          route =>
            !excludedLinks.includes(route.id) && (
              <Link
                to={getUrlFromId(lang, route.id)}
                key={`${lang}-${route.id}`}
              >
                <FooterLink>{general.pageTitles[route.id]}</FooterLink>
              </Link>
            )
        )}
        <FooterLink onClick={() => showConsent()}>
          {lang === 'de' ? 'Einwilligungseinstellungen' : 'Consent-Options'}
        </FooterLink>
      </LinkGroup>
    </FooterBlockRight>
  </FooterBar>
);

export default Footer;

const FooterBar = styled.footer`
  width: 100%;
  height: 240px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  ${({ theme }) =>
    theme === 'dark'
      ? css`
          background-color: ${colors.black};
          border-top: solid 1px #292929;
        `
      : css`
          background-color: ${colors.white};
          border-top: solid 1px #eaeaea;
        `}
  @media (max-width: 767px) {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

const FooterBlockLeft = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 64px;
  margin-bottom: 64px;
  margin-left: 80px;
  small {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 42px;
    margin-left: 0;
  }
`;

const FooterLogoContainer = styled.div`
  -ms-flex-item-align: start;
  align-self: flex-start;
  -ms-grid-column-align: center;
  justify-self: center;
  width: 32px;
`;

const FooterBlockRight = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 64px;
  margin-right: 79px;
  margin-bottom: 64px;
  small {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-top: 18px;
    margin-right: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
`;

const LinkGroup = styled.div`
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: right;
  small {
    color: ${({ theme }) => (theme === 'dark' ? colors.white : colors.black)};
  }
  @media (max-width: 767px) {
    text-align: center;
    margin-top: 40px;
    a:nth-child(1),
    a:nth-child(2),
    a:nth-child(3) {
      display: none;
    }

    a:nth-child(4),
    a:nth-child(5) {
      padding: 1px;
    }
  }
`;

const FooterLink = styled(Text.small)`
  color: ${colors.white};
  display: inline-block;
  margin-left: 24px;
  cursor: pointer;
  @media (max-width: 767px) {
    display: block;
    margin-left: 0;
  }
`;

const CopyrightContainer = styled.div`
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
  small {
    color: ${colors.grey}!important;
  }
`;
