import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import is from 'is_js';
import { Footer } from '.';
import {
  CookieDisclaimer,
  CookieData,
} from './CookieDisclaimer/CookieDisclaimer';
import { IeFallback } from './IeFallback/IeFallback';
import { useCookies } from 'react-cookie';

interface PageLayoutProps {
  userLanguage: string;
  generalText: Object;
  children: ReactNode;
  darkTheme?: boolean;
}

export const PageLayout = ({
  userLanguage,
  generalText,
  darkTheme = true,
  children,
}: PageLayoutProps) => {
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);
  useEffect(() => {
    !showDisclaimer && setShowDisclaimer(true);
  });

  // Extract cookie from JSON and filter only non-required cookie names
  const cookieNames = () =>
    generalText.cookieDisclaimer &&
    generalText.cookieDisclaimer.cookies
      .map((category: CookieData) =>
        !category.required ? category.name : undefined
      )
      .filter((result: string) => result !== undefined);

  // Initialize react-cookie libary
  const [cookies, setCookie, removeCookie] = useCookies(
    ['hide-consent'].concat(cookieNames())
  );

  const showConsent = () => removeCookie('hide-consent');

  return (
    <Fragment>
      {is.ie() ? (
        <IeFallback />
      ) : (
        <Fragment>
          {children}
          <Footer
            lang={userLanguage}
            general={generalText}
            darkTheme={darkTheme}
            showConsent={showConsent}
          />
          {showDisclaimer && (
            <CookieDisclaimer
              cookies={cookies}
              cookieNames={cookieNames}
              setCookie={setCookie}
              locale={userLanguage}
              cookieData={generalText.cookieDisclaimer}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
